import { useState, useEffect, useContext } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { AuthContext } from './AuthProvider';

const useUnselectedCards = () => {
    const [unselectedCards, setUnselectedCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user: currentUser } = useContext(AuthContext);

    useEffect(() => {
        const fetchUnselectedCards = async () => {
            if (!currentUser) {
                setError('ユーザーがログインしていません');
                setLoading(false);
                return;
            }

            try {
                const userRef = doc(db, "users", currentUser.uid);
                const userDoc = await getDoc(userRef);
                const historyData = userDoc.data()?.getHistory || [];

                const unselectedCards = historyData.flatMap(entry => 
                    entry.cards.filter(card => card.status === 'unselected')
                );

                setUnselectedCards(unselectedCards);
            } catch (e) {
                setError(`データの取得中にエラーが発生しました: ${e.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchUnselectedCards();
    }, [currentUser]);

    return { unselectedCards, loading, error };
};

export default useUnselectedCards;
