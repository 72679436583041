import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import '../../style/login.css';
import LogoIcon from '../../images/logo.png';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const auth = getAuth();

    const handleLogin = async (event) => {
        event.preventDefault();
        setError('');

        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/home');  // ここでログイン成功後にリダイレクトするページを指定
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div>
            <div className="login-logo"><Link to="/"><img src={LogoIcon}/></Link></div>
            <div className='login-body'>
                <div className="login-container">
                    <h1 className='login-h1'>ログイン</h1>
                    <form onSubmit={handleLogin} className='login-form'>
                        <input
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder="メールアドレス"
                            required
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            placeholder="パスワード"
                            required
                        />
                        <button type="submit" className='login-button'>ログイン</button>
                        {error && <p>{error}</p>}
                    </form>
                    <p className="to-signup"><Link to="/signup">初めての方はこちら</Link></p>
                </div>
            </div>
        </div>
    );
};

export default Login;
