import React, { useState } from 'react';
import { db } from '../../firebase-config';
import { doc, getDoc, collection, writeBatch } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import useCards from '../../hooks/useCards';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const CreateGacha = () => {
    const [gacha, setGacha] = useState({
        id: '',
        name: '',
        image: null,
        count: '',
        price: '',
    });

    const [cardQuantities, setCardQuantities] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('');
    const cards = useCards();

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'image') {
            setGacha({ ...gacha, image: files[0] });
        } else {
            setGacha({ ...gacha, [name]: value });
        }
    };

    const handleQuantityChange = (cardId, value) => {
        const quantity = value === '' ? undefined : Number(value);
        setCardQuantities(prev => ({
            ...prev,
            [cardId]: { ...prev[cardId], quantity }
        }));
    };

    const handleGradeChange = (cardId, grade) => {
        setCardQuantities(prev => ({
            ...prev,
            [cardId]: { ...prev[cardId], grade }
        }));
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!gacha.image) {
            alert('画像ファイルをアップロードしてください。');
            return;
        }

        const totalCards = Object.values(cardQuantities).reduce((sum, details) => sum + (details?.quantity || 0), 0);
        if (totalCards > parseInt(gacha.count)) {
            alert('カードの枚数が多すぎます。');
            return;
        }

        const cardList = [];
        const batch = writeBatch(db);
        for (const [cardId, details] of Object.entries(cardQuantities)) {
            if (details?.quantity > 0) {
                const cardRef = doc(db, "cards", cardId);
                const cardSnap = await getDoc(cardRef);
                if (cardSnap.exists()) {
                    for (let i = 0; i < details.quantity; i++) {
                        const newCard = {
                            ...cardSnap.data(),
                            cardId: cardId,
                            grade: details.grade || '5',
                        };
                        cardList.push(newCard);
                        const remainingCardRef = doc(collection(db, "gacha", gacha.id, "remainingCardList"));
                        batch.set(remainingCardRef, newCard);
                    }
                }
            }
        }

        const storage = getStorage();
        const storageRef = ref(storage, `gachaImages/${gacha.id}/${gacha.image.name}`);
        const snapshot = await uploadBytes(storageRef, gacha.image);
        const imageUrl = await getDownloadURL(snapshot.ref);

        const gachaRef = doc(db, "gacha", gacha.id);
        batch.set(gachaRef, {
            name: gacha.name,
            image: imageUrl,
            count: parseInt(gacha.count),
            remainingCards: parseInt(gacha.count),
            price: parseFloat(gacha.price),
            cardList
        });

        await batch.commit();

        alert('ガチャを正常に作成しました！');
    };

    const { user, isAdmin, loading } = useAuth();
    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user || !isAdmin) {
        return <Navigate to="/home" replace />;
    }

    // 選択されたカテゴリーでカードをフィルタリングし、価格順にソート
    const filteredCards = selectedCategory 
        ? cards.filter(card => card.genre === selectedCategory)
        : cards;

    const sortedCards = filteredCards.sort((a, b) => a.price - b.price);

    return (
        <div>
            <h1>新規ガチャ作成</h1>
            <form onSubmit={handleSubmit}>
                <input type="text" name="id" value={gacha.id} onChange={handleChange} placeholder="ガチャID" required />
                <input type="text" name="name" value={gacha.name} onChange={handleChange} placeholder="名前" required />
                <input type="file" name="image" onChange={handleChange} required />
                <input type="number" name="count" value={gacha.count} onChange={handleChange} placeholder="口数" required />
                <input type="number" name="price" value={gacha.price} onChange={handleChange} placeholder="価格" required />
                
                <select onChange={handleCategoryChange} value={selectedCategory}>
                    <option value="wanpi-figyua">ワンピ フィギュア</option>
                    <option value="game">ゲーム機</option>
                    <option value="gasyet">ガジェット</option>
                    <option value="pokemon-card">ポケモン カード</option>
                    <option value="">全て</option>
                </select>
                
                {sortedCards.map(card => (
                    <div key={card.id}>
                        <label>{card.name} - ¥{card.price}</label>
                        <input
                            type="number"
                            value={cardQuantities[card.id]?.quantity === undefined ? '' : cardQuantities[card.id].quantity}
                            onChange={(e) => handleQuantityChange(card.id, e.target.value)}
                            min="0"
                        />
                        <select
                            value={cardQuantities[card.id]?.grade || '5'}
                            onChange={(e) => handleGradeChange(card.id, e.target.value)}
                        >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                ))}
                <button type="submit">ガチャを作成</button>
            </form>
        </div>
    );
};

export default CreateGacha;
