import { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs } from 'firebase/firestore';

const useMovies = () => {
    const [movies, setMovies] = useState([]);

    useEffect(() => {
        const fetchMovies = async () => {
            const querySnapshot = await getDocs(collection(db, "movies"));
            const moviesData = querySnapshot.docs.map(doc => ({
                id: doc.id, // タイトルをドキュメントIDとして使用
                videoUrl: doc.data().videoUrl // 動画のURL
            }));
            setMovies(moviesData);
        };

        fetchMovies();
    }, []);

    return movies;
};

export default useMovies;
