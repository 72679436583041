import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import useGachaLogic from '../../hooks/useGachaLogic';
import useWallet from '../../hooks/useWallet';
import '../../style/gachaDetail.css';
import MenuIcon from '../../images/manu.png';
import LogoIcon from '../../images/logo.png';
import WalletImg from '../../images/wallet.png';
import gachaInfoImg from '../../images/gachaInfo.png';
import draw1Img from '../../images/draw1.png';
import draw10Img from '../../images/draw10.png';
import grade1Img from '../../images/Sgrade1.png';
import grade2Img from '../../images/Agrade2.png';
import grade3Img from '../../images/Bgrade3.png';
import grade4Img from '../../images/Cgrade4.png';
import grade5Img from '../../images/Cgrade4.png';

const GachaDetail = () => {
    const { id: gachaId } = useParams();
    const navigate = useNavigate();
    const [gacha, setGacha] = useState(null);
    const { handleDrawGacha, handleDrawMultipleGacha, loading, error } = useGachaLogic();
    const auth = getAuth();
    const { currentUser } = auth;
    const [menuOpen, setMenuOpen] = useState(false);
    const { wallet } = useWallet(currentUser?.uid);

    useEffect(() => {
        const fetchGacha = async () => {
            const docRef = doc(db, "gacha", gachaId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setGacha({ id: docSnap.id, ...docSnap.data() });
            } else {
                console.log("No such document!");
            }
        };

        fetchGacha();
    }, [gachaId]);

    const displayedCards = useMemo(() => {
        const cardLimits = {
            '1': 40,
            '2': 40,
            '3': 8,
            '4': 8,
            '5': 8
        };

        let filteredCards = [];
        const gradeMap = { '1': [], '2': [], '3': [], '4': [], '5': [] };

        if (gacha && gacha.cardList.length) {
            const uniqueCards = {};

            gacha.cardList.forEach(gachaCard => {
                const grade = gachaCard.grade.toString();
                const isUniqueGrade = ['3', '4', '5'].includes(grade);

                if (!uniqueCards[grade]) {
                    uniqueCards[grade] = new Set();
                }

                if (isUniqueGrade) {
                    if (!uniqueCards[grade].has(gachaCard.cardId)) {
                        uniqueCards[grade].add(gachaCard.cardId);
                        gradeMap[grade].push({ ...gachaCard });
                    }
                } else {
                    gradeMap[grade].push({ ...gachaCard });
                }
            });

            Object.keys(gradeMap).forEach(grade => {
                gradeMap[grade] = gradeMap[grade]
                    .sort((a, b) => b.price - a.price) // 金額順にソート
                    .slice(0, cardLimits[grade]); // 上限数までスライス
            });

            filteredCards = ['1', '2', '3', '4', '5'].flatMap(grade => gradeMap[grade]);
        }

        return filteredCards;
    }, [gacha]);

    const handleDrawGachaClick = async () => {
        try {
            await handleDrawGacha(gachaId);
        } catch (err) {
            console.error('Failed to draw gacha:', err);
        }
    };

    const handleDrawMultipleGachaClick = async () => {
        try {
            await handleDrawMultipleGacha(gachaId);
        } catch (err) {
            console.error('Failed to draw multiple gacha:', err);
        }
    };

    if (!gacha) {
        return <div className="square-spin-3"></div>;
    }

    if (loading) {
        return <div className="square-spin-3"></div>;
    }

    if (error) {
        return <div>エラー: {error}</div>;
    }

    const handleAdminRedirect = async () => {
        const user = auth.currentUser;
        if (user) {
            try {
                const idTokenResult = await user.getIdTokenResult(true);
                if (idTokenResult.claims.isAdmin) {
                    navigate('/admin');
                } else {
                    alert("アクセスが拒否されました。管理者権限がありません。");
                }
            } catch (error) {
                console.error('Error fetching token or claims:', error);
                alert("Failed to retrieve authentication details.");
            }
        } else {
            navigate('/login');
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const gradeImages = {
        '1': grade1Img,
        '2': grade2Img,
        '3': grade3Img,
        '4': grade4Img,
        '5': grade5Img
    };

    const gradeTitlesDisplayed = {};

    return (
        <div>
            <div className="header">
                <div className="logo"><Link to="/"><img src={LogoIcon} alt="Menu" /></Link></div>
                {currentUser ? (
                    <>
                        <div className="wallet">
                            <Link to="/point-page">
                                <img src={WalletImg} alt="wallet" />
                                <div className="wallet-text" style={{ fontWeight: "bold", right: "70px" }}>
                                    {loading ? <p>...</p> : error ? <p>エラー: {error}</p> : <p>{wallet}pt</p>}
                                </div>
                            </Link>
                        </div>
                        {!menuOpen && (
                            <div className="hamburger-menu" onClick={toggleMenu}>
                                <img src={MenuIcon} alt="Menu" />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="registration-btn">
                            <Link to="/login" className='login'>ログイン</Link>
                            <Link to="/signup" className='signup'>新規登録</Link>
                        </div>
                    </>
                )}
                <div className={`menu ${menuOpen ? 'menuOpen' : ''}`}>
                    {menuOpen && (
                        <div>
                            <span className="my-page-text" >マイページ</span>
                            <button className="close-menu" onClick={toggleMenu}>×</button>
                        </div>
                    )}
                    <p className="wallet-manu">所持ポイント：<span className="wallet-amount">{wallet}pt</span></p>
                    <p className="point-page"><Link to="/point-page">ポイント購入</Link></p>
                    <p className="add-information"><Link to="/add-information">住所登録</Link></p>
                    <p className="check-request"><Link to="/check-request">獲得履歴</Link></p>
                    <p className="unselectedCards-page"><Link to="/unselectedCards-page">未選択賞品</Link></p>
                    {menuOpen && (<span className="admin-dashboard-link" onClick={handleAdminRedirect}>管理ページ</span>)}
                </div>
                {menuOpen && (
                    <div className="menu-open-overlay" onClick={toggleMenu}></div>
                )}
            </div>
            <div className="body">
                <div key={gacha.id} className="gacha-components-d">
                    <img src={gacha.image} alt={gacha.name} />
                    <div className="info-img-d"><img src={gachaInfoImg} alt="gacha-d" /></div>
                    <div className="gacha-info-d">
                        <div className="gacha-price-d" style={{ fontSize: "25px" }}>{gacha.price}pt</div>
                        <div className="gacha-lot-d">
                            <div className="gacha-remaining-d" style={{ fontSize: "20px" }}>{gacha.remainingCards}</div>
                            <div className="gacha-total-d" style={{ fontSize: "20px" }}>/{gacha.count}</div>
                        </div>
                    </div>
                </div >
                <div className="draw-button">
                    <img 
                        src={draw1Img}
                        alt="ガチャを引く" 
                        onClick={handleDrawGachaClick} 
                        style={{ cursor: 'pointer' }}
                        className="draw1"
                    />
                    <div className="draw1-text">{gacha.price}pt</div>
                    <img 
                        src={draw10Img}
                        alt="10連ガチャを引く" 
                        onClick={handleDrawMultipleGachaClick} 
                        style={{ cursor: 'pointer' }}
                        className="draw10"
                    />
                    <div className="draw10-text">{gacha.price * 10}pt</div>
                </div>
                <div className="card-list">
                    {displayedCards.length > 0 ? displayedCards.map(card => {
                        if (!gradeTitlesDisplayed[card.grade]) {
                            gradeTitlesDisplayed[card.grade] = true;
                            return (
                                <React.Fragment key={card.uniqueKey}>
                                    <img src={gradeImages[card.grade]} alt={`Grade ${card.grade}`} className="grade-title" />
                                    <div className={`card card-grade-${card.grade}`}>
                                        <div className="overlay"></div>
                                        <div className="card-content">
                                            <img src={card.imageUrl || 'default_image.jpg'} alt={card.name || 'Unnamed Card'} />
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        }
                        return (
                            <div key={card.uniqueKey} className={`card card-grade-${card.grade}`}>
                                <div className="overlay"></div>
                                <div className="card-content">
                                    <img src={card.imageUrl || 'default_image.jpg'} alt={card.name || 'Unnamed Card'} />
                                </div>
                            </div>
                        );
                    }) : <p>No cards found.</p>}
                </div>
            </div>
        </div>
    );
};

export default GachaDetail;
