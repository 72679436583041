import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import useWallet from '../../hooks/useWallet';
import MenuIcon from '../../images/manu.png';
import LogoIcon from '../../images/logo.png';
import PointIcon from '../../images/pointIcon.png';
import WalletImg from '../../images/wallet.png';
import Coin from '../../images/coin.png';
import '../../style/PointPage.css';

const stripePromise = loadStripe('pk_live_51PDR8rP1nzkRR7whw8cKvNK2RLUvRB4MyvHhCVvihAK3DXQ0HZCpaOLfPaaLHzdfmd026o8sytknOWKhxw8Muceo000NRR1St9');

const PointPage = () => {
  const [stripe, setStripe] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showCancelMessage, setShowCancelMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const { currentUser } = auth;
  const [menuOpen, setMenuOpen] = useState(false);
  const { wallet, loading: walletLoading, error } = useWallet(currentUser?.uid);

  useEffect(() => {
    stripePromise.then((loadedStripe) => {
      if (loadedStripe) {
        setStripe(loadedStripe);
        console.log("Stripe is loaded and ready.", loadedStripe);
      }
    }).catch((error) => {
      console.error("Error loading Stripe:", error);
    });

    const params = new URLSearchParams(window.location.search);
    if (params.get('success')) {
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 5000);
    } else if (params.get('canceled')) {
      setShowCancelMessage(true);
      setTimeout(() => setShowCancelMessage(false), 5000);
    }
  }, []);

  const pointsOptions = [
    { points: 500, price: 500 },
    { points: 1000, price: 1000 },
    { points: 5000, price: 5000 },
    { points: 10000, price: 10000 },
    { points: 20000, price: 20000 },
    { points: 50000, price: 50000 },
    { points: 100000, price: 100000 },
    { points: 200000, price: 200000 }
  ];

  const handleAdminRedirect = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const idTokenResult = await user.getIdTokenResult(true);
        if (idTokenResult.claims.isAdmin) {
          navigate('/admin');
        } else {
          alert("アクセスが拒否されました。管理者権限がありません。");
        }
      } catch (error) {
        console.error('Error fetching token or claims:', error);
        alert("Failed to retrieve authentication details.");
      }
    } else {
      navigate('/login');
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const createCheckoutSession = async (price, points) => {
    setLoading(true);
    try {
      const response = await fetch('https://us-central1-gacha-fc74e.cloudfunctions.net/createCheckoutSession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await currentUser.getIdToken()}`
        },
        body: JSON.stringify({ price, points })
      });

      const session = await response.json();
      if (session.url) {
        window.location.href = session.url;
      } else {
        alert('Failed to create checkout session.');
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      alert('Failed to create checkout session.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`point-page-container ${loading ? 'loading' : ''}`}>
      <div className="header">
        <div className="logo"><Link to="/"><img src={LogoIcon} alt="Menu" /></Link></div>
        {currentUser ? (
          <>
            <div className="wallet">
              <Link to="/point-page">
                <img src={WalletImg} alt="wallet" />
                <div className="wallet-text" style={{ fontWeight: "bold", right: "70px" }}>
                  {walletLoading ? <p>...</p> : error ? <p>エラー: {error}</p> : <p>{wallet}pt</p>}
                </div>
              </Link>
            </div>
          </>
        ) : (
          <>
            <p><Link to="/login">ログイン</Link></p>
            <p><Link to="/signup">新規登録</Link></p>
          </>
        )}
        {!menuOpen && (
          <div className="hamburger-menu" onClick={toggleMenu}>
            <img src={MenuIcon} alt="Menu" />
          </div>
        )}
        <div className={`menu ${menuOpen ? 'menuOpen' : ''}`}>
          {menuOpen && (
            <div>
              <span className="my-page-text">マイページ</span>
              <button className="close-menu" onClick={toggleMenu}>×</button>
            </div>
          )}
          <p className="wallet-manu">所持ポイント：<span className="wallet-amount">{wallet}pt</span></p>
          <p className="point-page"><Link to="/point-page">ポイント購入</Link></p>
          <p className="add-information"><Link to="/add-information">住所登録</Link></p>
          <p className="check-request"><Link to="/check-request">獲得履歴</Link></p>
          <p className="unselectedCards-page"><Link to="/unselectedCards-page">未選択賞品</Link></p>
          {menuOpen && (<span className="admin-dashboard-link" onClick={handleAdminRedirect}>管理ページ</span>)}
        </div>
        {menuOpen && (
          <div className="menu-open-overlay" onClick={toggleMenu}></div>
        )}
      </div>
      <div className="body">
        <div className="point-icon"><img src={PointIcon} alt="PointIcon" /></div>
        {showSuccessMessage && <div className="success-message">購入が成功しました！</div>}
        {showCancelMessage && <div className="cancel-message">購入がキャンセルされました。</div>}
        {pointsOptions.map((option, index) => (
          <div key={index} className="point-option">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={Coin} alt="coin" />
              <p>{option.points} pt {option.label && <span style={{ color: 'red', marginLeft: '10px' }}>{option.label}</span>}</p>
            </div>
            <button onClick={() => createCheckoutSession(option.price, option.points)}>¥ {option.price}</button>
          </div>
        ))}
      </div>
      {loading && <div className="square-spin-3"></div>}
    </div>
  );
};

export default PointPage;
