import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import useGacha from '../../hooks/useGacha';
import useWallet from '../../hooks/useWallet';
import '../../style/home.css';
import MenuIcon from '../../images/manu.png';
import LogoIcon from '../../images/logo.png';
import WalletImg from '../../images/wallet.png';
import gachaInfoImg from '../../images/gachaInfo.png';
import linenup from '../../images/linenup.png';
import banner1 from '../../images/banner1.png';
import banner2 from '../../images/banner2.png';
import banner3 from '../../images/banner3.png';

const Home = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const { currentUser } = auth;
    const gachas = useGacha();
    const [menuOpen, setMenuOpen] = useState(false);
    const { wallet, loading, error } = useWallet(currentUser?.uid);

    const banners = [banner1, banner2, banner3,banner1];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const bannerRef = useRef();
    

    // // クローンするための配列を用意
    // const extendedBanners = [ ...banners, banners[0]];

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isTransitioning) {
                nextSlide();
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [currentIndex, isTransitioning]);

    const nextSlide = () => {
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => prevIndex + 1);
    };

    const handleTransitionEnd = () => {
        setIsTransitioning(false);
        // インデックスをリセット
        if (currentIndex+1 === banners.length) {
            setCurrentIndex(0);
            bannerRef.current.style.transition = 'none'; // アニメーションを一旦無効化
            bannerRef.current.style.transform = `translateX(0)`; // 最初の位置に戻す
            setTimeout(() => {
                bannerRef.current.style.transition = 'transform 0.7s ease-in-out'; // アニメーションを再度有効化
            }, 0);
        }else if (currentIndex === -1) {
            setCurrentIndex(banners.length - 1);
            bannerRef.current.style.transition = 'none';
            bannerRef.current.style.transform = `translateX(-${banners.length * 100}%)`;
            setTimeout(() => {
                bannerRef.current.style.transition = 'transform 0.7s ease-in-out';
            }, 0);
        }
    };

    const handleAdminRedirect = async () => {
        const user = auth.currentUser;
        if (user) {
            try {
                const idTokenResult = await user.getIdTokenResult(true);
                if (idTokenResult.claims.isAdmin) {
                    navigate('/admin');
                } else {
                    alert("アクセスが拒否されました。管理者権限がありません。");
                }
            } catch (error) {
                console.error('Error fetching token or claims:', error);
                alert("Failed to retrieve authentication details.");
            }
        } else {
            navigate('/login');
        }
    };

    const sortedGachas = [...gachas].sort((a, b) => {
        if (a.remainingCards === 0 && b.remainingCards !== 0) return 1;
        if (a.remainingCards !== 0 && b.remainingCards === 0) return -1;
        
        if (a.remainingCards !== 0 && b.remainingCards !== 0) {
            if (a.id === '301') return -1; // ガチャIDが301の場合、一番上に表示
            if (b.id === '301') return 1;  // ガチャIDが301の場合、一番上に表示
        }
        
        if (a.remainingCards === 0 && b.remainingCards === 0) return b.id.localeCompare(a.id);
        return b.id.localeCompare(a.id);
    }).slice(0, 10); 

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div>
            <div className="header">
                <div className="logo"><Link to="/"><img src={LogoIcon} alt="Menu" /></Link></div>
                {currentUser ? (
                    <>
                        <div className="wallet">
                            <Link to="/point-page">
                                <img src={WalletImg} alt="wallet" />
                                <div className="wallet-text" style={{fontWeight:"bold", right:"70px"}}>
                                    {loading ? <p>...</p> : error ? <p>エラー</p> : <p>{wallet}pt</p>}
                                </div>
                            </Link>
                        </div>
                        {!menuOpen && (
                            <div className="hamburger-menu" onClick={toggleMenu}>
                                <img src={MenuIcon} alt="Menu" />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="registration-btn">
                            <Link to="/login" className='login'>ログイン</Link>
                            <Link to="/signup" className='signup'>新規登録</Link>
                        </div>
                    </>
                )}
                <div className={`menu ${menuOpen ? 'menuOpen' : ''}`}>
                    {menuOpen && (
                        <div>
                            <span className="my-page-text" >マイページ</span>
                            <button className="close-menu" onClick={toggleMenu}>×</button>
                        </div>
                    )}
                    <p className="wallet-manu">所持ポイント：<span className="wallet-amount">{wallet}pt</span></p>
                    <p className="point-page"><Link to="/point-page">ポイント購入</Link></p>
                    <p className="add-information"><Link to="/add-information">住所登録</Link></p>
                    <p className="check-request"><Link to="/check-request">発送履歴</Link></p>
                    <p className="unselectedCards-page"><Link to="/unselectedCards-page">未選択賞品</Link></p>
                    {menuOpen && (<span className="admin-dashboard-link" onClick={handleAdminRedirect}>管理ページ</span>)}
                </div>
                {menuOpen && (
                    <div className="menu-open-overlay" onClick={toggleMenu}></div>
                )}
            </div>
            <div className="body">
                <div className="banner-container">
                <div className="banner-line">
                    <div className="banner-carousel"
                        ref={bannerRef}
                        style={{
                            transform: `translateX(-${((currentIndex) * 100) /1}%)`,
                            transition: 'transform 1s ease-in-out',
                        }}
                        onTransitionEnd={handleTransitionEnd}
                    >
                        {banners.map((banner, index) => (
                            <img
                                key={index}
                                src={banner}
                                alt={`banner${index + 1}`}
                                className={`banner-img ${currentIndex === index ? 'active' : ''}`}
                            />
                        ))}
                    </div>
                </div>
                </div>
                <div className="gacha-container">
                    <div className="linenup"> <img src={linenup} alt='linenup'></img></div>
                    {sortedGachas.map(gacha => (
                        gacha.remainingCards === 0 ? (
                            <div className='gacha'>
                                <div className="gacha-components" key={gacha.id}>
                                    <div className="gacha-overlay active"><h2>SOLD OUT</h2></div>
                                    <div className="gacha-content">
                                        <img src={gacha.image} alt={gacha.name} />
                                        <div className="info-img"><img src={gachaInfoImg} alt="gacha" /></div>
                                        <div className="gacha-info">
                                            <div className="gacha-price" style={{ fontSize: "25px" }}>{gacha.price}pt</div>
                                            <div className="gacha-lot">
                                                <div className="gacha-remaining" style={{ fontSize: "20px" }}>{gacha.remainingCards}</div>
                                                <div className="gacha-total" style={{ fontSize: "20px" }}>/{gacha.count}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Link to={`/gacha/${gacha.id}`} className="gacha" key={gacha.id}>
                                <div className="gacha-components">
                                    <div className="gacha-content">
                                        <img src={gacha.image} alt={gacha.name} />
                                        <div className="info-img"><img src={gachaInfoImg} alt="gacha" /></div>
                                        <div className="gacha-info">
                                            <div className="gacha-price" style={{ fontSize: "25px" }}>{gacha.price}pt</div>
                                            <div className="gacha-lot">
                                                <div className="gacha-remaining" style={{ fontSize: "20px" }}>{gacha.remainingCards}</div>
                                                <div className="gacha-total" style={{ fontSize: "20px" }}>/{gacha.count}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    ))}
                </div>
            </div>
            <div className="fooder">
                <p className="inquiry"><Link to="https://docs.google.com/forms/d/e/1FAIpQLScc0uLpbsX1G5HP9uWgZ_ntwrlJgRHWkQ7Z-4girRsvnB_TlA/viewform?usp=sf_link">お問い合わせはこちら</Link></p>
                <p className="terms"><Link to="/terms">利用規約</Link></p>
                <p className="transaction-law"><Link to="/transaction-law">特定商取引法に基づく表記</Link></p>
            </div>
        </div>
    );
};

export default Home;
