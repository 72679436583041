import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../../hooks/AuthProvider';
import useUnselectedCards from '../../hooks/useUnselectedCards';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../../firebase-config';
import { getAuth } from 'firebase/auth';
import useWallet from '../../hooks/useWallet';
import '../../style/result.css';
import MenuIcon from '../../images/manu.png';
import LogoIcon from '../../images/logo.png';
import WalletImg from '../../images/wallet.png';
import grade1Img from '../../images/Sgrade1.png';
import grade2Img from '../../images/Agrade2.png';
import grade3Img from '../../images/Bgrade3.png';
import grade4Img from '../../images/Cgrade4.png';
import grade5Img from '../../images/Cgrade4.png';
import req1Img from '../../images/req-1.png';
import req2Img from '../../images/req-2.png';

const gradeImages = {
    '1': grade1Img,
    '2': grade2Img,
    '3': grade3Img,
    '4': grade4Img,
    '5': grade5Img
};

const Modal = ({ isOpen, onClose, onConfirm, children, confirmVisible = true, loading }) => {
    if (!isOpen || loading) return null;
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <p>{children}</p>
                <div className="modal-button">
                    {confirmVisible && <button className="confirm-button" onClick={onConfirm}>確定</button>}
                    <button className="cancel-button" onClick={onClose}>キャンセル</button>
                </div>
            </div>
        </div>
    );
};

const UnselectedCardsPage = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const { unselectedCards, loading, error } = useUnselectedCards();
    const { wallet, loading: walletLoading, error: walletError } = useWallet(currentUser?.uid);
    const [checkedState, setCheckedState] = useState(new Array(unselectedCards.length).fill(false));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalAction, setModalAction] = useState(null);
    const [loadingState, setLoading] = useState(false);
    const { user } = useContext(AuthContext);
    const functions = getFunctions(app);
    const handleCardOperations = httpsCallable(functions, 'handleCardOperations');
    const [menuOpen, setMenuOpen] = useState(false);

    const handleCheckChange = (index) => {
        setCheckedState(prevState => {
            const updatedCheckedState = [...prevState];
            updatedCheckedState[index] = !updatedCheckedState[index];
            return updatedCheckedState;
        });
    };

    const prepareShipCards = () => {
        const checkedCards = unselectedCards.filter((card, index) => checkedState[index]);
        const checkedCardIds = checkedCards.map(card => card.cardId);
        const uncheckedCards = unselectedCards.filter((card, index) => !checkedState[index]);
        const uncheckedCardIds = uncheckedCards.map(card => card.cardId);
        const totalUnselectedPrice = uncheckedCards.reduce((total, card) => total + card.price, 0);

        if (checkedCardIds.length === 0) {
            setModalContent('発送するカードが選択されていません。');
            setIsModalOpen(true);
            setModalAction(null);
        } else {
            const cardNames = checkedCards.map(card => `・${card.name}`).join('<br>');
            const cardCount = checkedCards.length;
            setModalContent(`下記のカードを発送します。<br><br>${cardNames}<br><br>必要発送ポイント: ${120 * cardCount}pt<br><br>他のカードはポイントに還元します。<br>合計: ${totalUnselectedPrice}pt<br><br>よろしいですか？`);
            setIsModalOpen(true);
            setModalAction(() => async () => {
                setLoading(true);
                try {
                    const result = await handleCardOperations({ checkedCardIds, uncheckedCardIds });
                    console.log('Shipping successful:', result.data);
                    setLoading(false);
                    setIsModalOpen(false);
                    navigate(`/home`, { replace: true });
                } catch (error) {
                    console.error('Failed to ship:', error);
                    setLoading(false);
                    setModalContent(`発送に失敗しました: ${error.message}`);
                    setIsModalOpen(true);
                    setModalAction(null);
                }
            });
        }
    };

    const prepareRefundAllCards = () => {
        const uncheckedCards = unselectedCards.filter((card, index) => !checkedState[index]);
        const uncheckedCardIds = uncheckedCards.map(card => card.cardId);
        const totalUnselectedPrice = uncheckedCards.reduce((total, card) => total + card.price, 0);
        console.log('Preparing to refund all cards:', { uncheckedCardIds });

        if (uncheckedCardIds.length === unselectedCards.length) {
            setModalContent(`全ての賞品をポイントに変換します。<br>合計: ${totalUnselectedPrice}pt`);
            setIsModalOpen(true);
            setModalAction(() => async () => {
                setLoading(true);
                try {
                    const result = await handleCardOperations({ checkedCardIds: [], uncheckedCardIds });
                    console.log('Point refund successful:', result.data);
                    setLoading(false);
                    setIsModalOpen(false);
                    navigate(`/home`, { replace: true });
                } catch (error) {
                    console.error('Failed to refund points:', error);
                    setLoading(false);
                    setModalContent(`ポイント還元に失敗しました: ${error.message}`);
                    setIsModalOpen(true);
                    setModalAction(null);
                }
            });
        } else {
            setModalContent(`発送する賞品が選択されています。<br>全ポイント還元はできません。`);
            setIsModalOpen(true);
            setModalAction(null);
        }
    };

    const handleAdminRedirect = async () => {
        const user = auth.currentUser;
        if (user) {
            try {
                const idTokenResult = await user.getIdTokenResult(true);
                if (idTokenResult.claims.isAdmin) {
                    navigate('/admin');
                } else {
                    alert("アクセスが拒否されました。管理者権限がありません。");
                }
            } catch (error) {
                console.error('Error fetching token or claims:', error);
                alert("Failed to retrieve authentication details.");
            }
        } else {
            navigate('/login');
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div>
            <div className="header">
                <div className="logo"><Link to="/"><img src={LogoIcon} alt="Menu" /></Link></div>
                {currentUser ? (
                    <>
                        <div className="wallet">
                            <Link to="/point-page">
                                <img src={WalletImg} alt="wallet" />
                                <div className="wallet-text" style={{ fontWeight: "bold", right: "70px" }}>
                                    {walletLoading ? <p>...</p> : walletError ? <p>エラー</p> : <p>{wallet}pt</p>}
                                </div>
                            </Link>
                        </div>
                        {!menuOpen && (
                            <div className="hamburger-menu" onClick={toggleMenu}>
                                <img src={MenuIcon} alt="Menu" />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="registration-btn">
                            <Link to="/login" className='login'>ログイン</Link>
                            <Link to="/signup" className='signup'>新規登録</Link>
                        </div>
                    </>
                )}
                <div className={`menu ${menuOpen ? 'menuOpen' : ''}`}>
                    {menuOpen && (
                        <div>
                            <span className="my-page-text" >マイページ</span>
                            <button className="close-menu" onClick={toggleMenu}>×</button>
                        </div>
                    )}
                    <p className="wallet-manu">所持ポイント：<span className="wallet-amount">{wallet}pt</span></p>
                    <p className="point-page"><Link to="/point-page">ポイント購入</Link></p>
                    <p className="add-information"><Link to="/add-information">住所登録</Link></p>
                    <p className="check-request"><Link to="/check-request">発送履歴</Link></p>
                    <p className="unselectedCards-page"><Link to="/unselectedCards-page">未選択賞品</Link></p>
                    {menuOpen && (<span className="admin-dashboard-link" onClick={handleAdminRedirect}>管理ページ</span>)}
                </div>
                {menuOpen && (
                    <div className="menu-open-overlay" onClick={toggleMenu}></div>
                )}
            </div>
            <div className={`result-body ${loadingState ? 'loading' : ''}`}>
                {loadingState && <div className="square-spin-3"></div>}
                {unselectedCards.length === 0 ? (
                    <p style={{ fontWeight: "bold"}}>未選択のカードはありません</p>
                ) : (
                    <>
                        {unselectedCards.sort((a, b) => a.grade - b.grade).map((card, index) => (
                            <div key={index} className="card-container">
                                <div className="card-info">
                                    <img src={gradeImages[card.grade]} alt={`Grade ${card.grade}`} />
                                    <p className='result-card-name'>{card.name}</p>
                                    <p className='result-card-price'>還元: <span className="card-price-amount">{card.price}pt</span></p>
                                    <input
                                        type="checkbox"
                                        id={`check-${index}`}
                                        name={`check-${index}`}
                                        className="result-checkbox"
                                        checked={checkedState[index]}
                                        onChange={() => handleCheckChange(index)}
                                    />
                                    <label htmlFor={`check-${index}`} className="custom-checkbox-label">
                                        <span className="checkbox"></span>
                                        発送 (120pt)
                                    </label>
                                </div>
                                <div className="card-image-container">
                                    <div className="card-image">
                                        <img src={card.imageUrl} alt={card.name} />
                                        <div className={`overlay overlay-grade-${card.grade}`}></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {unselectedCards.length > 0 && (
                            <div className="request-button">
                                <img
                                    src={req1Img}
                                    alt="選択カードを発送"
                                    onClick={prepareShipCards}
                                    style={{ cursor: 'pointer' }}
                                    className="req-1"
                                />
                                <img
                                    src={req2Img}
                                    alt="全てポイント還元"
                                    onClick={prepareRefundAllCards}
                                    style={{ cursor: 'pointer' }}
                                    className="req-2"
                                />
                            </div>
                        )}
                    </>
                )}
                <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onConfirm={modalAction}
                    confirmVisible={modalAction !== null}
                    loading={loadingState}>
                    <span dangerouslySetInnerHTML={{ __html: modalContent }} />
                </Modal>
            </div>
        </div>
    );
};

export default UnselectedCardsPage;
