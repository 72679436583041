import React from 'react';
import { Navigate, Link, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const AdminDashboard = () => {
    const { user, isAdmin, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user || !isAdmin) {
        return <Navigate to="/home" replace />;
        // return <p>404 NOT FOUND</p>
    }

    return (
        <div>
            <h1>管理者メニュー</h1>
            <ul>
                <li><Link to="/home">ホーム</Link></li>
                <li><Link to="/admin">管理者メニュー</Link></li>
                <li><Link to="cards-list">View Card List</Link></li>
                <li><Link to="add-card">Add New Card</Link></li>
                <li><Link to="add-movie">Add New Movie</Link></li>
                <li><Link to="create-gacha">Create New Gacha</Link></li>
                <li><Link to="user-request">発送申請</Link></li>
            </ul>
            <Outlet />
        </div>
    );
};

export default AdminDashboard;
