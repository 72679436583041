import { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { doc, getDoc } from 'firebase/firestore';

const useWallet = (userId) => {
    const [wallet, setWallet] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchWallet = async () => {
            if (!userId) {
                setError("No user ID provided");
                setLoading(false);
                return;
            }

            try {
                const userRef = doc(db, "users", userId);
                const docSnap = await getDoc(userRef);

                if (docSnap.exists()) {
                    setWallet(docSnap.data().wallet); 
                } else {
                    throw new Error("User not found");
                }
            } catch (err) {
                setError(err.message);
                console.error("Error fetching wallet:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchWallet();
    }, [userId]);

    return { wallet, loading, error };
};

export default useWallet;