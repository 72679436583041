import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { db } from '../../firebase-config'; // Firestoreインスタンスのインポート
import { getAuth } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore'; // Firestoreドキュメント操作のためのメソッドインポート
import { AuthContext } from '../../hooks/AuthProvider';
import useWallet from '../../hooks/useWallet';
import '../../style/addInfomation.css';
import MenuIcon from '../../images/manu.png';
import LogoIcon from '../../images/logo.png';
import WalletImg from '../../images/wallet.png';

const AddInformation = () => {
    const [information, setInformation] = useState({ firstName: '', lastName: '', firstKanaName: '', lastKanaName: '', postCode: '', address: '', phoneNumber: '' });
    const { user: currentUser } = useContext(AuthContext);

    const navigate = useNavigate();
    const auth = getAuth();
    const [menuOpen, setMenuOpen] = useState(false);
    const { wallet, loading, error } = useWallet(currentUser?.uid);

    const handleAdminRedirect = async () => {
        const user = auth.currentUser;
        if (user) {
            try {
                const idTokenResult = await user.getIdTokenResult(true);
                if (idTokenResult.claims.isAdmin) {
                    navigate('/admin');
                } else {
                    alert("アクセスが拒否されました。管理者権限がありません。");
                }
            } catch (error) {
                console.error('Error fetching token or claims:', error);
                alert("Failed to retrieve authentication details.");
            }
        } else {
            navigate('/login');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        // 数字のみを許可するバリデーション
        if (name === 'phoneNumber') {
            const re = /^[0-9\b]+$/;
            if (value === '' || re.test(value)) {
                setInformation({ ...information, [name]: value });
            }
        } else if (name === 'postCode' && value.length === 7) {
            setInformation({ ...information, [name]: value });
            searchAddress(value);
        } else {
            setInformation({ ...information, [name]: value });
        }
    };

    const searchAddress = async (postCode) => {
        const url = `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postCode}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.results) {
                const address = data.results[0];
                const fullAddress = `${address.address1} ${address.address2} ${address.address3}`;
                setInformation(prevState => ({
                    ...prevState,
                    address: fullAddress
                }));
            } else {
                alert('住所が見つかりませんでした。');
            }
        } catch (error) {
            console.error('Error fetching address:', error);
            alert('住所検索中にエラーが発生しました。');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const userDocRef = doc(db, 'users', currentUser.uid); // ユーザードキュメントの参照を取得

        try {
            await setDoc(userDocRef, {
                firstName: information.firstName,
                lastName: information.lastName,
                firstKanaName: information.firstKanaName,
                lastKanaName: information.lastKanaName,
                postCode: information.postCode,
                address: information.address,
                phoneNumber: information.phoneNumber,
            }, { merge: true }); // 既存のドキュメントに新しい情報をマージする
            setInformation({ firstName: '', lastName: '', firstKanaName: '', lastKanaName: '', postCode: '', address: '', phoneNumber: '' }); // フォームをリセット
            alert('登録完了しました！');
            navigate(-1); // 元の画面に遷移
        } catch (error) {
            console.error("Error adding document: ", error);
            alert('Error adding Information!');
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div>
            <div className="header-addInfo">
                <div className="logo"><Link to="/"><img src={LogoIcon} alt="Menu" /></Link></div>
                {currentUser ? (
                    <>
                        <div className="wallet">
                            <Link to="/point-page">
                                <img src={WalletImg} alt="wallet" />
                                <div className="wallet-text" style={{fontWeight:"bold", right:"70px"}}>
                                    {loading ? <p>...</p> : error ? <p>エラー: {error}</p> : <p>{wallet}pt</p>}
                                </div>
                            </Link>
                        </div>
                    </>
                ) : (
                    <>
                        <p><Link to="/login">ログイン</Link></p>
                        <p><Link to="/signup">新規登録</Link></p>
                    </>
                )}
                {!menuOpen && (
                    <div className="hamburger-menu" onClick={toggleMenu}>
                        <img src={MenuIcon} alt="Menu" />
                    </div>
                )}
                <div className={`menu ${menuOpen ? 'menuOpen' : ''}`}>
                    {menuOpen && (
                        <div>
                            <span className="my-page-text" >マイページ</span>
                            <button className="close-menu" onClick={toggleMenu}>×</button>
                        </div>
                    )}
                    <p className="wallet-manu">所持ポイント：<span className="wallet-amount">{wallet}pt</span></p>
                    <p className="point-page"><Link to="/point-page">ポイント購入</Link></p>
                    <p className="add-information"><Link to="/add-information">住所登録</Link></p>
                    <p className="check-request"><Link to="/check-request">獲得履歴</Link></p>
                    <p className="unselectedCards-page"><Link to="/unselectedCards-page">未選択賞品</Link></p>
                    {menuOpen && (<span className="admin-dashboard-link" onClick={handleAdminRedirect}>管理ページ</span>)}
                </div>
                {menuOpen && (
                    <div className="menu-open-overlay" onClick={toggleMenu}></div>
                )}
            </div>

            <div className='body-info'>
                <div className='infoForm'>
                    <h1>住所登録</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="label-container">
                            <div>
                                <label className="label" htmlFor="firstName">姓</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    placeholder="姓"
                                    value={information.firstName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label className="label" htmlFor="lastName">名</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    placeholder="名"
                                    value={information.lastName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="label-container">
                            <div>
                                <label className="label" htmlFor="firstKanaName">姓（カナ）</label>
                                <input
                                    type="text"
                                    name="firstKanaName"
                                    id="firstKanaName"
                                    placeholder="姓（カナ）"
                                    value={information.firstKanaName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label className="label" htmlFor="lastKanaName">名（カナ）</label>
                                <input
                                    type="text"
                                    name="lastKanaName"
                                    id="lastKanaName"
                                    placeholder="名（カナ）"
                                    value={information.lastKanaName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <label className="label" htmlFor="postCode">郵便番号(ハイフン不要)</label>
                        <input
                            type="text"
                            name="postCode"
                            id="postCode"
                            placeholder="郵便番号(ハイフン不要)"
                            pattern="\d{7}"
                            value={information.postCode}
                            onChange={handleChange}
                            required
                        />
                        <label className="label" htmlFor="address">住所</label>
                        <input
                            type="text"
                            name="address"
                            id="address"
                            placeholder="住所"
                            value={information.address}
                            onChange={handleChange}
                            required
                        />
                        <label className="label" htmlFor="phoneNumber">電話番号</label>
                        <input
                            type="text"
                            name="phoneNumber"
                            id="phoneNumber"
                            placeholder="電話番号"
                            value={information.phoneNumber}
                            onChange={handleChange}
                            required
                        />

                        <button className="save-button" type="submit">保存する</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddInformation;
