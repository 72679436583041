import React from 'react';

const Terms = () => {

    return (
        <div>
            <body>
                <h1>利用規約</h1>
                <p>本利用規約（以下「本規約」と言う。）は、インターネットサービス「1000円ガチャonline」において、当社が提供するサービス（以下「本サービス」と言う。）の利用について、利用者にご利用いただくための条件を定めたものです。本サービスのご利用に当たっては、本規約および当社のプライバシーポリシーへの同意が必要です。本サービスを利用した利用者は、本規約および当社のプライバシーポリシーに同意したものとみなします。</p>

                <div class="section">
                    <h2>第1章 総則</h2>
                    <div class="article">
                        <h3>第1条（定義）</h3>
                        <p>本規約において、以下の用語は、別途定義されている場合及び文脈上別異に解すべきことが明確である場合を除き、以下の意味を有するものとします。本条の定義は、本規約においても適用されるものとします。</p>
                        <p class="indent">1. 「本規約」とは、本利用規約、利用ガイド及び当社のウェブサイトに掲載する利用条件等を指します。</p>
                        <p class="indent">2. 「プライバシーポリシー」とは、個人情報の取扱いについて、当社が別途定める規程を指します。</p>
                        <p class="indent">3. 「利用ガイド」とは、当社が別途定める本サービスの利用に関する取扱いのマニュアルを指します。</p>
                        <p class="indent">4. 「ユーザー」とは、本規約の内容に同意し、本サービスを利用する個人または法人を指します。</p>
                        <p class="indent">5. 「個人情報」とは、個人情報の保護に関する法律第2条第1項で定義されるユーザーの個人情報を指します。</p>
                        <p class="indent">6. 「商品」とは、本サービスで獲得される物品等を指します。</p>
                        <p class="indent">7. 「ポイント」とは、本サービスで使用できる仮想通貨を指します。</p>
                    </div>
                    <div class="article">
                        <h3>第2条（本サービス）</h3>
                        <p class="indent">1. 本サービスの内容については、本規約、当社が利用ガイド及び当社のウェブサイトに掲載する利用条件等に準じます。</p>
                        <p class="indent">2. 当社は、当社の判断により、本サービスの内容を変更または提供を終了することができます。</p>
                        <p class="indent">3. ユーザーは、当社による本サービスの内容の変更または提供の終了について、異議を唱えたり、損害賠償その他の請求をすることはできません。</p>
                    </div>
                </div>

                <div class="section">
                    <h2>第2章 ユーザー登録</h2>
                    <div class="article">
                        <h3>第3条（ユーザー登録）</h3>
                        <p class="indent">1. 本サービスの利用を希望する者（以下「登録希望者」と言う。）は、本規約の遵守に同意し、当社が別途規定する情報（以下「登録情報」と言う。）を当社に提供し、本サービスの利用のための登録を申請することができます。</p>
                        <p class="indent">2. 登録の申請は、必ず本サービスを利用する個人または法人の代表者・従業員自身が行わなければなりません。代理人による登録申請は認めません。登録希望者は、登録申請の際に、真実で正確かつ最新の情報を提供しなければなりません。</p>
                        <p class="indent">3. 未成年者が登録を希望する場合は、法定代理人の同意を得て、本サービスを利用しなければなりません。法定代理人に対して確認を行う場合があります。</p>
                        <p class="indent">4. 登録希望者は、複数のユーザー登録を行うことができません。</p>
                        <p class="indent">5. 当社は、登録希望者が以下の各号のいずれかに該当すると判断した場合、登録を拒否することがあります。</p>
                        <p class="indent indent">1. 本規約に違反するおそれがある場合、または過去に本規約に違反したことがある場合</p>
                        <p class="indent indent">2. 本サービスまたは当社提供の他のサービスについて、過去に登録を取り消されたことがある場合</p>
                        <p class="indent indent">3. 登録情報の全部または一部が虚偽、誤記または記載漏れであった場合</p>
                        <p class="indent indent">4. 反社会的勢力等との関与がある場合</p>
                        <p class="indent indent">5. その他、登録に適さないと当社が判断した場合</p>
                        <p class="indent">6. 当社は、前項の規定に則り、登録の可否を判断します。登録が認められた場合、登録希望者に通知し、ユーザー登録が完了します。</p>
                        <p class="indent">7. 登録情報に変更があった場合、ユーザーは、速やかに当社に通知し、当該変更に関わる情報及び資料を提出しなければなりません。</p>
                    </div>
                </div>

                <div class="section">
                    <h2>第3章 ユーザーの義務</h2>
                    <div class="article">
                        <h3>第4条（アカウント情報の管理）</h3>
                        <p class="indent">1. ユーザーは、本サービスに関するユーザーID及びパスワード（以下「アカウント情報」と言う。）を、自己の責任において管理及び保管するものとします。ユーザーは、アカウント情報を第三者に利用させたり、貸与や譲渡をしたり、名義変更や売買等の行為は禁止します。</p>
                        <p class="indent">2. アカウント情報の管理不十分、使用上の過誤、第三者の使用による損害の責任はユーザーが負うものとし、当社は一切責任を負いません。</p>
                        <p class="indent">3. アカウント情報が盗まれたり、第三者に使用されたりしていることが判明した場合には、ユーザーは速やかにその旨を当社に通知し、当社の指示に従わなければなりません。</p>
                    </div>
                    <div class="article">
                        <h3>第5条（禁止事項）</h3>
                        <p>ユーザーは、本サービスの利用において、以下の行為を行ってはなりません。</p>
                        <p class="indent">1. 他のユーザーの資格・権利を利用して本サービスを利用すること</p>
                        <p class="indent">2. 当社・他のユーザーの利益を侵害すること</p>
                        <p class="indent">3. 当社・他のユーザーの知的財産権等の権利を侵害すること、またはそのおそれのある行為</p>
                        <p class="indent">4. 当社・他のユーザーに経済的な損害・損失を与えること</p>
                        <p class="indent">5. 法令・本規約に違反する行為</p>
                        <p class="indent">6. 本規約の趣旨に反する行為</p>
                        <p class="indent">7. 当社のサービス運営を妨げること</p>
                        <p class="indent">8. 自分の氏名やユーザーID以外の人物を名乗ること</p>
                        <p class="indent">9. 青少年の心身に悪影響を与えるような行為</p>
                        <p class="indent">10. 公序良俗に反する行為</p>
                        <p class="indent">11. 当社が倫理的に認められないと判断する行為</p>
                        <p class="indent">12. 当社の運営以外のウェブサイト・リソースへリンクを貼ること</p>
                        <p class="indent">13. コンピュータ・ウィルスの送信、コンピュータの機器・通信回線・ソフトウェア等の機能に悪影響を与えること</p>
                        <p class="indent">14. 当社提供のサービスに関するサーバー・ネットワークに対して悪影響を与えること</p>
                        <p class="indent">15. 当社のサービス提供に関連するシステムに対して、不正にアクセスすること</p>
                        <p class="indent">16. 当社提供のインターフェイスとは別の手法によってサービスにアクセスすること</p>
                        <p class="indent">17. 当社のウェブサイト関連のシステム・ソフトウェアのセキュリティホールやエラー、バグを利用すること</p>
                        <p class="indent">18. 当社のウェブサイト関連のシステム・ソフトウェア・プロトコル等をリバースエンジニアリングや逆アセンブル等の手法によって解読すること、若しくはこれらを改ざん・修正等すること、又はこれらを複製・二次利用すること</p>
                        <p class="indent">19. その他、当社が適切でないと判断する行為</p>
                    </div>
                </div>

                <div class="section">
                    <h2>第4章 本サービスの提供・停止</h2>
                    <div class="article">
                        <h3>第6条（本サービスの停止等）</h3>
                        <p>以下のいずれかに該当する場合、ユーザーに事前に通知することなく、当社は、本サービスの利用の全部または一部を停止または中断することができます。</p>
                        <p class="indent">1. 定期的または緊急に、本サービスに関するコンピュータ・システムの点検・保守作業を行う場合</p>
                        <p class="indent">2. 事故によって、コンピュータ、通信回線等が停止した場合</p>
                        <p class="indent">3. 不可抗力（火災、停電、天災地変等）によって、本サービスの提供ができなくなった場合</p>
                        <p class="indent">4. 当社以外の事業者が提供する当社所定のサービスにトラブル、サービス提供の中断や停止、本サービスとの連携の停止、サービスの仕様の変更等が生じた場合</p>
                        <p class="indent">5. その他停止または中断を当社が必要と判断した場合</p>
                        <p class="indent">2. 当社は、当社の都合によって、本サービスの提供を停止または終了することができます。この場合、ユーザーに対して事前に通知します。</p>
                        <p class="indent">3. 本条に基づいて当社が執った措置が原因でユーザーに損害が生じた場合、当社は一切の責任を負いません。</p>
                    </div>
                </div>

                <div class="section">
                    <h2>第5章 商品・ポイントの取扱い</h2>
                    <div class="article">
                        <h3>第7条（商品の取扱い）</h3>
                        <p class="indent">1. ユーザーは、本サービスを利用して獲得した商品の配送を受けることができます。</p>
                        <p class="indent">2. ユーザーは、獲得した商品の配送を希望する場合、本サイトから所定の配送手続を行わなければなりません。</p>
                        <p class="indent">3. 当社は、商品の配送が困難となった場合、当該商品に結びつく還元ポイントをユーザーに付与することによって責任を免れます。当社は、同一の商品を取得し、ユーザーに交付する義務を負いません。</p>
                        <p class="indent">4. 商品に関する配送料は、一商品あたり120ポイント徴収します。国外への配送については、別途料金を徴収します。</p>
                        <p class="indent">5. 当社は、商品の発送中において発生した破損等について、一切の責任を負いません。ユーザーが商品の発送を希望する場合、商品の獲得後48時間以内に発送先住所を登録し、発送手続きをしなければなりません。時間を過ぎた場合、または発送先住所が登録されなかった場合、自動でポイントに変換されます。</p>
                        <p class="indent">6. 当社がユーザーに商品を配送した後、ユーザーの受け取り拒否、宛先不明等で返送され、再送手続を行わなかった場合、ユーザーは商品の権利を失います。ポイントの返還義務等は一切負いません。</p>
                    </div>
                    <div class="article">
                        <h3>第8条（ポイントの取扱い）</h3>
                        <p class="indent">1. ユーザーが一定の基準を満たした場合、当社はポイントを付与することができます。ポイントは本サービス内で商品獲得の際に使用できます。</p>
                        <p class="indent">2. ポイントの取得率、取得条件、失効条件、利用可能な対象商品等に関する各種条件は、当社が別途指定するものとします。</p>
                        <p class="indent">3. ポイントの有効期間は、ポイント獲得日から2ヶ月間です。期間内にポイントを使用しなかった場合、ポイントは自動的に失効します。</p>
                        <p class="indent">4. ポイントの有効期間は、当社がユーザーにポイントを付与した日を起点とします。キャンセル等の理由で使用したポイントが返還された場合も異なる取り扱いはしません。</p>
                        <p class="indent">5. 退会または登録が取り消された場合、保有するポイントは全て失効します。当社がポイント失効について連絡する義務はありません。</p>
                        <p class="indent">6. ポイントは本サービス内でのみ使用でき、現金に換算して払い戻すことはありません。</p>
                    </div>
                </div>

                <div class="section">
                    <h2>第6章 その他</h2>
                    <div class="article">
                        <h3>第9条（ユーザーの退会）</h3>
                        <p class="indent">1. ユーザーが退会を希望する場合、当社規定の方法で申し出なければなりません。当社が認めた場合、ユーザーは退会できます。</p>
                        <p class="indent">2. 取引の決済等が完了していない場合、退会はできません。未了の決済等を完了した上で再度申し出なければなりません。</p>
                        <p class="indent">3. 退会に起因する損害について、当社は一切責任を負いません。</p>
                    </div>
                    <div class="article">
                        <h3>第10条（個人情報等の取扱い）</h3>
                        <p class="indent">1. 当社は、プライバシーポリシーに従って個人情報等を取り扱います。</p>
                        <p class="indent">2. ユーザーは、本サービスの利用前にプライバシーポリシーを確認し、理解した上で本サービスを利用するものとします。</p>
                        <p class="indent">3. 当社は、個人情報を第三者に提供することがあります。ユーザーは、当該提供について予め同意するものとします。</p>
                    </div>
                    <div class="article">
                        <h3>第11条（本規約の変更）</h3>
                        <p class="indent">1. 当社は、ユーザーの同意なしで本規約を変更できるものとします。</p>
                        <p class="indent">2. 本規約を変更する場合、変更後の本規約の内容・効力発生日を事前に周知します。</p>
                    </div>
                    <div class="article">
                        <h3>第12条（協議解決）</h3>
                        <p>本規約に定めのない事項または本規約の解釈に疑義が生じた場合、当社とユーザーは誠実に協議し解決を図るものとします。</p>
                    </div>
                    <div class="article">
                        <h3>第13条（準拠法及び管轄裁判所）</h3>
                        <p>本規約の準拠法は日本法とし、本規約に起因または関連する一切の紛争は、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。</p>
                    </div>
                </div>

                <p>2024年7月18日制定</p>
            </body>        
        </div>
    );
};

export default Terms;