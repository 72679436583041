import { useState, useEffect } from 'react';
import { db } from '../firebase-config';
import { collection, getDocs } from 'firebase/firestore';

const useGacha = () => {
    const [gachas, setGachas] = useState([]);

    useEffect(() => {
        const fetchGachas = async () => {
            const querySnapshot = await getDocs(collection(db, "gacha"));
            const gachasData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setGachas(gachasData);
        };

        fetchGachas();
    }, []);

    return gachas;
};

export default useGacha;
