import { useState, useEffect, useContext } from 'react';
import { doc, getDoc, collection, query, where, getDocs, documentId } from 'firebase/firestore';
import { db } from '../firebase-config';
import { AuthContext } from './AuthProvider';

const useCheckRequest = () => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user: currentUser } = useContext(AuthContext);

    useEffect(() => {
        const fetchUserRequests = async () => {
            if (!currentUser) {
                setError('ユーザーがログインしていません');
                setLoading(false);
                return;
            }

            try {
                const userRef = doc(db, "users", currentUser.uid);
                const userDoc = await getDoc(userRef);
                const historyData = userDoc.data()?.requestHistory || [];

                // リクエストIDのみを抽出
                const requestIds = historyData.map(item => item.requestId).filter(id => id);

                if (requestIds.length === 0) {
                    setError('リクエスト履歴が存在しません');
                    setLoading(false);
                    return;
                }

                const batchLimit = 30;
                const requestData = [];

                // リクエストIDを30個ごとに分割してクエリを実行
                for (let i = 0; i < requestIds.length; i += batchLimit) {
                    const batch = requestIds.slice(i, i + batchLimit);
                    const requestsQuery = query(collection(db, "sendingRequest"), where(documentId(), "in", batch));
                    const querySnapshot = await getDocs(requestsQuery);

                    querySnapshot.forEach(doc => {
                        requestData.push({ id: doc.id, ...doc.data() });
                    });
                }

                setRequests(requestData);
            } catch (e) {
                setError(`データの取得中にエラーが発生しました: ${e.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchUserRequests();
    }, [currentUser]);

    return { requests, loading, error };
};

export default useCheckRequest;
