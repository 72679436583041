import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import useWallet from '../../hooks/useWallet';
import '../../style/CheckRequest.css';
import MenuIcon from '../../images/manu.png';
import LogoIcon from '../../images/logo.png';
import WalletImg from '../../images/wallet.png';
import useCheckRequest from '../../hooks/useCheckRequest';

const CheckRequest = () => {
    const { requests, loading, error } = useCheckRequest();
    const navigate = useNavigate();
    const auth = getAuth();
    const { currentUser } = auth;
    const [menuOpen, setMenuOpen] = useState(false);
    const { wallet } = useWallet(currentUser?.uid);

    const handleAdminRedirect = async () => {
        const user = auth.currentUser;
        if (user) {
            try {
                const idTokenResult = await user.getIdTokenResult(true);
                if (idTokenResult.claims.isAdmin) {
                    navigate('/admin');
                } else {
                    alert("アクセスが拒否されました。管理者権限がありません。");
                }
            } catch (error) {
                console.error('Error fetching token or claims:', error);
                alert("Failed to retrieve authentication details.");
            }
        } else {
            navigate('/login');
        }
    };

    if (loading) return <div className="square-spin-3"></div>;
    if (error) return <div>エラー: {error}</div>;
    if (requests.length === 0) return <div>リクエストが見つかりません。</div>;

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div>
            <div className="header">
                <div className="logo"><Link to="/"><img src={LogoIcon} alt="Menu" /></Link></div>
                {currentUser ? (
                    <>
                        <div className="wallet">
                            <Link to="/point-page">
                                <img src={WalletImg} alt="wallet" />
                                <div className="wallet-text" style={{fontWeight:"bold", right:"70px"}}>
                                    {loading ? <p>...</p> : error ? <p>エラー: {error}</p> : <p>{wallet}pt</p>}
                                </div>
                            </Link>
                        </div>
                    </>
                ) : (
                    <>
                        <p><Link to="/login">ログイン</Link></p>
                        <p><Link to="/signup">新規登録</Link></p>
                    </>
                )}
                {!menuOpen && (
                    <div className="hamburger-menu" onClick={toggleMenu}>
                        <img src={MenuIcon} alt="Menu" />
                    </div>
                )}
                <div className={`menu ${menuOpen ? 'menuOpen' : ''}`}>
                    {menuOpen && (
                        <div>
                            <span className="my-page-text" >マイページ</span>
                            <button className="close-menu" onClick={toggleMenu}>×</button>
                        </div>
                    )}
                    <p className="wallet-manu">所持ポイント：<span className="wallet-amount">{wallet}pt</span></p>
                    <p className="point-page"><Link to="/point-page">ポイント購入</Link></p>
                    <p className="info-manu">配送先：<span className="info-amount">{wallet}pt</span></p>
                    <p className="add-information"><Link to="/add-information">住所登録</Link></p>
                    <p className="check-request"><Link to="/check-request">獲得履歴</Link></p>
                    <p className="unselectedCards-page"><Link to="/unselectedCards-page">未選択賞品</Link></p>
                    {menuOpen && (<span className="admin-dashboard-link" onClick={handleAdminRedirect}>管理ページ</span>)}
                </div>
                {menuOpen && (
                    <div className="menu-open-overlay" onClick={toggleMenu}></div>
                )}
            </div>
            <div className="body">
                <h1>発送申請カード一覧</h1>
                {requests
                    .sort((a, b) => b.timestamp - a.timestamp) // Sort by timestamp, newest first
                    .map(request => (
                        <div key={request.id} className="req-card-container">
                            <p className="timestamp">{request.timestamp.toDate().toLocaleString('ja-JP', { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</p>
                            <p className="status">{request.status}</p>
                            <div className="req-card-list">
                                <ul>
                                    {request.cardList.map(card => (
                                        <li key={card.id}>
                                            <div className="req-card-info">
                                                <p className="card-name">{card.name}</p>
                                                <p className="card-price">{card.price}pt</p>
                                            </div>
                                            {card.imageUrl && <img src={card.imageUrl} alt={card.name} className="req-card-img" />}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <h2 className="request-id">リクエストID: {request.requestId}</h2>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default CheckRequest;
