import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PlayMovie = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement && state.videoUrl) {
            videoElement.src = state.videoUrl;
            videoElement.load();

            const onLoadedMetadata = () => {
                videoElement.play().catch(err => {
                    console.error("Error attempting to play video:", err);
                });
            };

            videoElement.addEventListener('loadedmetadata', onLoadedMetadata);
            videoElement.onended = () => {
                if (state.cards) {
                    navigate('/result', { replace: true, state: { cards: state.cards, gachaId: state.gachaId } });
                } else {
                    console.error("No card data available to navigate with.");
                }
            };

            return () => {
                videoElement.removeEventListener('loadedmetadata', onLoadedMetadata);
            };
        }
    }, [state, navigate]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <video 
                ref={videoRef} 
                controls 
                style={{ width: '100%', maxWidth: '430px' }}>
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default PlayMovie;
