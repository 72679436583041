import React, { useState } from 'react';
import { db } from '../../../firebase-config'; // Firestoreインスタンスのインポート
import { collection, addDoc } from 'firebase/firestore'; // Firestoreドキュメント操作
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase Storage操作
import { Navigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

const AddCard = () => {
    const [card, setCard] = useState({ name: '', price: 0, genre: '' });
    const [file, setFile] = useState(null); // ファイルの状態を管理するstate

    const handleChange = (e) => {
        setCard({ ...card, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]); // 選択されたファイルをstateにセット
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            alert('Please upload an image file for the card.');
            return;
        }
        const storage = getStorage();
        const storageRef = ref(storage, `cards/${file.name}`); // ストレージの参照を設定

        try {
            const snapshot = await uploadBytes(storageRef, file); // ファイルをアップロード
            const imageUrl = await getDownloadURL(snapshot.ref); // アップロードしたファイルのURLを取得

            await addDoc(collection(db, "cards"), {
                name: card.name,
                imageUrl: imageUrl,
                price: parseFloat(card.price) || 0,
                genre: card.genre // ジャンルを追加
            }); // Firestoreにドキュメントを追加
            setCard({ name: '', price: 0, genre: '' }); // フォームをリセット
            alert('住所を登録しました！');
        } catch (error) {
            console.error("Error adding document: ", error);
            alert('Error adding card!');
        }
    };

    const { user, isAdmin, loading } = useAuth();
    if (loading) {
        return <div>Loading...</div>;
    }

    if (!user || !isAdmin) {
        return <Navigate to="/home" replace />;
    }

    return (
        <div>
            <h1>Add New Card</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    placeholder="Card Name"
                    value={card.name}
                    onChange={handleChange}
                    required
                />
                <input
                    type="file"
                    onChange={handleFileChange}
                    required
                />
                <input
                    type="number"
                    name="price"
                    placeholder="Price"
                    value={card.price}
                    onChange={handleChange}
                    required
                />
                <select
                    name="genre"
                    value={card.genre}
                    onChange={handleChange}
                    required
                >
                    <option value="wanpi-figyua">ワンピ フィギュア</option>
                    <option value="game">ゲーム機</option>
                    <option value="gasyet">ガジェット</option>
                    <option value="pokemon-card">ポケモン カード</option>
                    <option value="">ジャンル無し</option>
                </select>
                <button type="submit">Add Card</button>
            </form>
        </div>
    );
};

export default AddCard;
