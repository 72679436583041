import React from 'react';

const TransactionLaw = () => {

    return (
        <div>
            <h1>特定商取引法に基づく表記</h1>
            <p>本サービス内で「ポイント」をご購入いただくにあたり、特定商取引に関する法律第１１条に基づき、以下のとおり表示いたします。</p>
            <h2>メールアドレス</h2>
            <p>support@1000yengacha.com</p>
            <h2>送料について</h2>
            <p>【通常配送】発送一件あたり 全国一律120pt</p>
            <h2>販売価格</h2>
            <p>ポイントの購入画面に税込金額を表示いたします。</p>
            <h2>販売価格以外にご負担いただく費用</h2>
            <p>ウェブサイトの閲覧等に必要となるインターネット接続料金、通信料金等はお客様のご負担となります。</p>
            <h2>受け付け可能な決済手段</h2>
            <p>クレジットカード applePay GooglePay</p>
            <h2>決済期間</h2>
            <p>ポイントの提供前に、各種支払い方法でお支払いいただきます。</p>
            <h2>引渡時期</h2>
            <p>お支払いの手続き完了後、直ちに提供いたします。商品の発送に関しましては、申請後１４日以内の発送手続きとさせていただいております。<br></br>※天候や災害によって配送が遅れる場合がございます。</p>
            <h2>返品・キャンセル</h2>
            <p>ポイント、商品の返品、交換、換金等はできません。</p>
            <h2>所在地</h2>
            <p>請求があったら遅滞なく開示します</p>
            <h2>電話番号</h2>
            <p>請求があったら遅滞なく開示します</p>
            <h2>運営統括責任者</h2>
            <p>請求があったら遅滞なく開示します</p>
            <h2>販売事業者の名称</h2>
            <p>請求があったら遅滞なく開示します</p>            
        </div>
    );
};

export default TransactionLaw;