import { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { app } from '../firebase-config';
import useMovies from './useMovies';
import { AuthContext } from './AuthProvider';
import useWallet from './useWallet'; // ウォレット情報を取得するカスタムフック

const useGachaLogic = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { user: currentUser } = useContext(AuthContext);
    const { wallet, loading: walletLoading, error: walletError } = useWallet(currentUser?.uid);
    const movies = useMovies();
    const functions = getFunctions(app);

    useEffect(() => {
        if (currentUser) {
            const auth = getAuth();
            auth.currentUser.getIdToken(true).then(token => {
                functions.auth = { token };
            }).catch(err => {
                console.error('Failed to retrieve token:', err);
                setError('認証トークンの取得に失敗しました');
            });
        }
    }, [currentUser]);

    const drawGacha = httpsCallable(functions, 'drawGacha');
    const drawMultipleGacha = httpsCallable(functions, 'drawMultipleGacha');

    // 事前にムービーデータをメモ化
    const moviesById = useMemo(() => {
        return movies.reduce((acc, movie) => {
            acc[movie.id] = movie.videoUrl;
            return acc;
        }, {});
    }, [movies]);

    const handleDrawGacha = async (gachaId) => {
        console.log("Sending gachaId to Firebase Functions:", gachaId);
        if (!currentUser) {
            navigate('/login');
            return;
        }
        if (walletLoading || walletError) {
            setError(walletError || "ウォレット情報をロード中です");
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const result = await drawGacha({ gachaId });
            const card = result.data.cards[0];
            const videoUrl = moviesById[card.grade.toString()];
            navigate('/playMovie', { state: { videoUrl: videoUrl, cards: result.data.cards, gachaId } });
            setLoading(false);
        } catch (err) {
            console.error('Failed to draw gacha:', err);
            setError(err.message || 'ガチャの抽選に失敗しました');
            setLoading(false);
        }
    };

    const handleDrawMultipleGacha = async (gachaId) => {
        console.log("Sending gachaId to Firebase Functions:", gachaId);
        if (!currentUser) {
            navigate('/login');
            return;
        }
        if (walletLoading || walletError) {
            setError(walletError || "ウォレット情報をロード中です");
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const result = await drawMultipleGacha({ gachaId });
            const cards = result.data.cards;
            const lowestGradeCard = cards.reduce((lowest, card) => (lowest.grade > card.grade) ? card : lowest, cards[0]);
            const videoUrl = moviesById[lowestGradeCard.grade.toString()];
            navigate('/playMovie', { state: { videoUrl: videoUrl, cards, gachaId } });
            setLoading(false);
        } catch (err) {
            console.error('Failed to draw multiple gacha:', err);
            setError(err.message || '複数回のガチャ抽選に失敗しました');
            setLoading(false);
        }
    };

    return { handleDrawGacha, handleDrawMultipleGacha, loading, error };
};

export default useGachaLogic;
