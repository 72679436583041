import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import SignUp from './components/auth/SignUp';
import Home from './components/common/Home';
import Login from './components/auth/Login';
import AdminDashboard from './components/admin/AdminDashboard';
import PointPage from './components/common/PointPage';
import AddInformation from './components/common/AddInformation';
import UnselectedCardsPage from './components/common/UnselectedCardsPage';
import CardsList from './components/admin/cards/CardsList';
import AddCard from './components/admin/cards/AddCard';
import AddMovie from './components/admin/cards/AddMovie';
import CreateGacha from './components/admin/CreateGacha';
import GachaDetail from './components/gacha/GachaDetail';
import ResultPage from './components/gacha/ResultPage';
import CheckRequest from './components/common/CheckRequest';
import PlayMovie from './components/gacha/PlayMovie';
import UserRequest from './components/admin/UserRequest'; 
import TransactionLaw from './components/common/TransactionLaw.js'; 
import Terms from './components/common/Terms.js'; 
import { AuthProvider } from './hooks/AuthProvider';
import { ProvideAuth } from './hooks/useAuth';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ProvideAuth>
          <AuthProvider>
            <Routes>
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/home" element={<Home />} />
              <Route path="/point-page" element={<PointPage />} />
              <Route path="/transaction-law" element={<TransactionLaw />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/add-information" element={<AddInformation />} />
              <Route path="/unselectedCards-page" element={<UnselectedCardsPage />} />
              <Route path="/check-request" element={<CheckRequest />} />
              <Route path="/gacha/:id" element={<GachaDetail />} />
              <Route path="/playMovie" element={<PlayMovie />} />
              <Route path="/result" element={<ResultPage />} />
              <Route path="/" element={<Navigate replace to="/home" />} />
              <Route path="/admin" element={<AdminDashboard />}>
                <Route path="cards-list" element={<CardsList />} />
                <Route path="add-card" element={<AddCard />} />
                <Route path="add-movie" element={<AddMovie />} />
                <Route path="create-gacha" element={<CreateGacha />} />
                <Route path="user-request" element={<UserRequest />} />
              </Route>
            </Routes>
          </AuthProvider>
        </ProvideAuth>
      </BrowserRouter>
    </div>
  );
}

export default App;
