import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { db } from '../../firebase-config'; // Assuming db is your Firestore instance
import { doc, setDoc } from 'firebase/firestore'; // Firestore document operations
import '../../style/signup.css';
import LogoIcon from '../../images/logo.png';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const auth = getAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            alert('アカウントを作成しました！');
            
            // Create user document in Firestore
            await setDoc(doc(db, "users", userCredential.user.uid), {
                email: email,
                createdAt: new Date(),
                wallet: 0
            });

            // Redirect to /add-information
            navigate('/add-information');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div>
            <div className="login-logo"><Link to="/"><img src={LogoIcon} alt="Logo" /></Link></div>
            <div className='signup-body'>
                <div className="signup-container">
                    <h1 className='login-h1'>新規登録</h1>
                    <form onSubmit={handleSubmit} className='signup-form'>
                        <input
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder="メールアドレス"
                            required
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            placeholder="パスワード"
                            required
                        />
                        <button type="submit" className='signup-button'>新規登録</button>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </form>
                    <p className="to-login"><Link to="/login">ログインはこちら</Link></p>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
