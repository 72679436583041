import React, { useState } from 'react';
import { db } from '../../../firebase-config';
import { doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Navigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

const AddMovie = () => {
    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    const handleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            console.error('Please upload a video file.');
            return;
        }
        setUploading(true);
        const storage = getStorage();
        const storageRef = ref(storage, `movies/${file.name}`);

        try {
            const snapshot = await uploadBytes(storageRef, file);
            const videoUrl = await getDownloadURL(snapshot.ref);

            await setDoc(doc(db, "movies", title), {
                videoUrl: videoUrl
            });

            setTitle('');
            setFile(null);
            setUploading(false);
        } catch (error) {
            console.error("Error adding movie: ", error);
            setUploading(false);
        }
    };

    const { user, isAdmin, loading } = useAuth();
    if (loading) return <div>Loading...</div>;
    if (!user || !isAdmin) return <Navigate to="/home" replace />;

    return (
        <div>
            <h1>Add New Movie</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Movie Title"
                    value={title}
                    onChange={handleChange}
                    required
                />
                <input
                    type="file"
                    accept="video/*"
                    onChange={handleFileChange}
                    required
                />
                <button type="submit" disabled={uploading}>
                    {uploading ? 'Uploading...' : 'Add Movie'}
                </button>
            </form>
        </div>
    );
};

export default AddMovie;
