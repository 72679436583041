import React from 'react';
import useCards from '../../../hooks/useCards'; 

const CardsList = () => {
    const cards = useCards();

    return (
        <div>
            <h1>Card List</h1>
            <div>
                {cards.map(card => (
                    <div key={card.id}>
                        <img src={card.imageUrl} alt={card.name} style={{ maxWidth: '200px', maxHeight: '200px' }}/>
                        <h2>{card.name}</h2>
                        <p>Price: {card.price}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CardsList;
