import React from 'react';
import useRequest from '../../hooks/useRequest';
import useAuth from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';

const UserRequest = () => {
    const { requests, loading, error } = useRequest();
    const { user, isAdmin } = useAuth();

    if (!user || !isAdmin) {
        return <Navigate to="/home" replace />;
    }

    if (loading) return <div>ローディング中...</div>;
    if (error) return <div>エラー: {error}</div>;
    if (requests.length === 0) return <div>送信リクエストが見つかりません。</div>;

    return (
        <div>
            <h1>送信リクエスト一覧</h1>
            {requests.map(request => (
                <div key={request.id} style={{ margin: '10px', padding: '10px', border: '1px solid #ccc' }}>
                    <h2>リクエストID: {request.requestId}</h2>
                    <p>ユーザーID: {request.uId}</p>
                    <p>カードリスト:</p>
                    <ul>
                        {request.cardList.map((card, index) => (
                            <li key={index}>{card.name} - 価格: {card.price}円</li>
                        ))}
                    </ul>
                    <p>合計金額: {request.totalPrice}円</p>
                    <p>ステータス: {request.status}</p>
                    <p>タイムスタンプ: {request.timestamp.toDate().toLocaleString()}</p>
                </div>
            ))}
        </div>
    );
};

export default UserRequest;
